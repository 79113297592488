<template>
  <div>
    <div class="card p-2 bg-card">
      <div class="row">
        <div class="col-12">
          <h3 class="ml-1 mt-1" style="color: #fff">
            <input
              type="text"
              :value="companydetail.name"
              class="edit-company"
              ref="cname"
              @change="editcompanyname($event)"
            />
            <b-button
              variant="flat-primary"
              class="btn-icon"
              style="margin-left: -15px"
              @click="focusProjectTitleEdit()"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>

            <!-- {{ companydetail.name }} -->
          </h3>
          <!-- <div class="p-1">
            <div>
              <b-badge
                variant="warning"
                class="mr-50"
                v-for="mod in companydetail.modules"
                :key="mod._id"
                >{{ mod.name }}</b-badge
              ><feather-icon
                icon="Edit2Icon"
                @click="openModuleSelect"
                style="cursor: pointer; color: #fff"
              />
            </div>
          </div> -->
          <button
            class="btn btn-primary btn-sm float-right mt-1 mr-1 mb-1"
            @click="openConsultantModal()"
          >
            Add Secusy+ User
          </button>
          <button
            class="btn btn-warning btn-sm float-right mt-1 mr-1 mb-1"
            @click="openaddUsertoTenant()"
          >
            Add User
          </button>
        </div>
      </div>
    </div>
    <b-tabs class="mt-1">
      <b-tab title="Users">
        <div class="card p-2">
          <table class="table border mt-1">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">User's Name</th>
                <th scope="col">Email</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in companydetail.users" :key="user._id">
                <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                <td>
                  {{ user.firstname }} {{ user.lastname }}
                  <span
                    v-if="user.user_type == 'GRC_user'"
                    style="font-size: 10px"
                    class="text-danger"
                    >Consultant</span
                  >
                  <span
                    v-if="user.user_type == 'Vendor'"
                    style="font-size: 10px"
                    class="text-danger"
                    >Third Party</span
                  >
                </td>
                <td>{{ user.email }}</td>

                <td>
                  <button class="btn btn-primary btn-sm mr-1" @click="manageModule(user._id)">Manage Roles</button>
                  <span
                    @click="removeUser(user._id)"
                    class="icon-box-new-user pointer"
                  >
                    <feather-icon
                      class="delete-color rotate"
                      icon="Trash2Icon"
                      size="20"
                    />
                  </span>
                  <button class="btn btn-primary btn-sm" @click="inviteUser(user._id)">Invite</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-12 float-right">
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                hide-goto-end-buttons
                :total-rows="lastPage * perPage"
                class="float-right"
              />
            </div>
          </div>

          <b-modal ref="modal-adduser" title="Assign Secusy+ User " hide-footer>
            <!-- {{assignusers}} -->
            <!-- <v-select
        v-model="selecteduser"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="optionuser"
        
      /> -->
            <label for="">User</label>
            <select
              name=""
              id=""
              v-model="selectedconsultant"
              class="form-control"
            >
              <option
                :value="user._id"
                v-for="user in this.assignusers"
                :key="user._id"
              >
                {{ user.firstname }} {{ user.lastname }}
              </option>
            </select>
            <!-- {{assignusers}}
        {{selectedconsultant}} -->

            <button
              class="btn btn-primary btn-sm mt-1"
              @click="assignConsultant()"
            >
              Assign Secusy+ User
            </button>
          </b-modal>
        </div>
      </b-tab>
      <b-tab title="Modules">
        <table class="table border mt-1">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Module Name</th>
                <th scope="col">Enable/Disable</th>
              </tr>
            </thead>
            <tbody>
             <tr v-for="(mod,index) in companydetail.modules" :key="mod._id">
              <td>{{ index + 1 }}</td>
              <td>{{ mod.name }}</td>
              <td>
                <!-- <button class="btn btn-sm btn-primary" @click="manageModule()">Manage</button> -->
                <b-form-checkbox
                  checked="false"
                  class="custom-control-info"
                  name="check-button"
                  v-model="mod.status"
                  switch
                  @change="enableModule(mod._id,mod.status)"
                />
              </td>
             </tr>
            </tbody>
          </table>
      </b-tab>
    </b-tabs>

    <b-sidebar
      id="add-sub"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="addusertotenant"
      lazy
    >
      <div class="p-2">
        <validation-observer ref="observer">
          <b-form-group label="Email" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="basicInput"
                placeholder="Email"
                v-model="emailid"
                @input="getemail"
              />
              <!-- {{emaillist}} -->
              <small v-if="emaillist.length"
                >User is already exist.Please Add to continue</small
              >
              <!-- <ul style="padding-left: 0px !important">
                <li
                  v-for="user in emaillist"
                  :key="user._id"
                  style="
                    list-style: none;
                    background: #aee4ff;
                    width: max-content;
                    margin-top: 1rem;
                    padding: 5px;
                    cursor: pointer;
                    border-radius: 5px;
                  "
                  @click="selectExistingUser(user)"
                >
                  {{ user.email }}
                  <span>
                    <feather-icon
                      icon="PlusCircleIcon"
                      size="16"
                      v-if="selectexist"
                    />
                    <feather-icon
                      icon="CheckIcon"
                      size="16"
                      v-else
                      class="text-success"
                    />
                  </span>
                </li>
                <small v-if="!selectexist" class="mt-1"
                  >User Selected click add to continue</small
                >
              </ul> -->
              <!-- <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="option"
              /> -->
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="First Name" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Firstname"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="First Name"
                v-model="fname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Last Name" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Lastname"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="Last Name"
                v-model="lname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Phone Number" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Phonenumber"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="Phone Number"
                v-model="phonenumber"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-model="should_invite">
              Send invitation to mail
            </b-form-checkbox>
          </b-form-group>

          <template v-if="emaillist.length">
            <button
              class="btn btn-primary mt-1 mr-1"
              @click.prevent="AddExistUserTenant"
            >
              Add User
            </button>
          </template>
          <template v-else>
            <button
              class="btn btn-primary mt-1 mr-1"
              @click.prevent="validationFormsub"
            >
              Add User
            </button>
          </template>
          <button class="btn btn-secondary mt-1" @click="closeAdduser()">
            Cancel
          </button>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-modal
      id="modal-danger"
      ref="modal-danger"
      ok-only
      ok-variant="danger"
      @ok="deleteCustomerUser()"
      modal-class="modal-danger"
      centered
      title="Delete !"
    >
      <b-card-text> Are You Sure to Delete the User ? </b-card-text>
    </b-modal>
    <b-modal ref="modal-editmodule" title="Edit Modules " hide-footer>
      <b-form-group label="Modules" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selectedModules"
          :options="new_option_module"
          :aria-describedby="ariaDescribedby"
          name="flavour-1"
        ></b-form-checkbox-group>
      </b-form-group>
      <button class="btn btn-primary btn-sm mt-1" @click="editmodules()">
        Save Changes
      </button>
    </b-modal>
    <b-sidebar
      id="edit_module_sidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="edit_module_sidebar"
      lazy
    >
      <div class="p-2">
        <h5 class="mb-1">Edit Roles in Each Modules</h5>
        <!-- {{ module_details }} -->
        <!-- {{ modules_list }} -->
        <div v-for="item in module_details" :key="item._id">
            <!-- {{ item }} -->
            <!-- {{item.module.name}} -->
          <div
            class="d-flex justify-content-between mb-1"
           
          >
            <p>{{ item.module.name }}</p>
            <div class="d-flex align-items-center">
               
              <div>
                <span  v-if="item.role " @click="getRoleList(item.module._id,index)" style="cursor: pointer;border: 2px dotted #ccc;padding: 5px 10px;border-radius: 3px;" title="Click to change role" @blur="endEditing">{{ item.role }}</span>
                <span  v-if="item.role == null " @click="getRoleList(item.module._id)" style="cursor: pointer;border: 2px dotted #ccc;padding: 5px 10px;border-radius: 3px;font-size: 12px;" title="Click to change role"  @blur="endEditing" >+</span>
              </div>
              <select
                name=""
                id=""
                class="form-control mr-1 ml-1 form-control-sm"
                style="max-width: 150px"
                @change="updateTenantModule(item.module._id,item.role)"
                v-model="item.role"
                v-if="item.module._id == module_id && isediting"
                @blur="endEditing"
              >
              <option value="null" selected>-Select Role-</option>
                <option :value="item.name" v-for="item in role_list" :key="item._id">{{ item.name }}</option>
              </select>
              <span @click="removeModule(item.module._id)" v-if="item.status == 1" class="remove-module">X</span>
            </div>
          </div>
        </div>
        <button class="btn btn-secondary" @click="closeEditWorkspace">
          Cancel
        </button>
      </div>
    </b-sidebar>
       <b-modal ref="modal-invite" title="Copy Invite " hide-footer>
      <div v-if="genLink">
        <input
          type="text"
          class="form-control mt-1"
          v-model="genLink"
          ref="invite-link"
        />

        <p class="text-success">
          Link Generated Successfully ! Please click below button to copy the
          link
        </p>
      </div>

      <button
        class="btn btn-primary btn-sm mt-1"
        @click="copylink()"
        :disabled="genLink == ''"
      >
        Copy Link
      </button>
    </b-modal>
  </div>
</template>
<script>
import {
  BSidebar,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BPagination,
  BTabs,
  BTab,
  BBadge,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      customerid: "",
      tenantid: "",
      companyusers: [],
      tenantid: "",
      add_user: false,
      required,
      email,
      fname: "",
      lname: "",
      companyname: "",
      emailid: "",
      phonenumber: "",
      should_invite: "",
      options: [
        { text: "Admin", value: "admin" },
        { text: "User", value: "user" },
        { text: "CISO", value: "ciso" },
        { text: "VM Consultant", value: "vm_consultant" },
      ],
      companydetail: [],
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      addusertotenant: false,
      emaillist: [],
      selectexist: true,
      selectedconsultant: "",
      assignusers: [],
      modules_list: [],
      selectedModules: [],
      new_option_module: [],
      edit_module_sidebar:false,
      uid:'',
      module_details:[],
      role_list:[],
      module_id:'',
      isediting:false,
       inviteUserId: "",
      genLink: "",
    };
  },
  components: {
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BPagination,
    BTabs,
    BTab,
    BBadge,
  },
  watch: {
    $route(to, from) {
      this.customerid = this.$route.params.id;
      console.log(this.customerid);
      this.getMembers(this.customerid);
      this.getConsultants();
    },
    currentPage(value) {
      this.getCompanyMembers(value);
    },
  },
  mounted() {
    this.getMembers(this.customerid);
    this.getConsultants();
    this.getCompanyMembers(this.currentPage);
  },
  methods: {
       copylink() {
      const element = this.$refs["invite-link"];
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Link Copied !",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    inviteUser(userid) {
      this.inviteUserId = userid;
      
      this.generateInviteLink();
    },
    generateInviteLink() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/reinvite-link?user_id=${this.inviteUserId}`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.genLink = res.data.data.invite_link;
            this.$refs["modal-invite"].show();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    endEditing(){
      this.isediting = false;
    },
    getRoleList(moduleid){
      this.isediting = true;
      this.module_id = moduleid;
      console.log(moduleid)
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user-tenant-module/get-all-roles?tenant_id=${this.$route.params.id}&module_id=${moduleid}&user_id=${this.$route.params.id}`,
      };
      this.$http(options)
        .then((res) => {
          
          this.role_list = res.data.data;
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    manageModule(uid){
      this.edit_module_sidebar = true;
      this.uid = uid
      this.getModules( this.customerid);
    },
    closeEditWorkspace(){
      this.edit_module_sidebar = false;
    },
    getModuleList() {
      const module_items = [];
      this.module_details.map((item) => {
        module_items.push(item.module);
      });
      this.modules_list = module_items;
    },
    updateTenantModule(moduleid,role) {
      // console.log(moduleid + " ->moduleid" + this.user_id +"->userid" + this.tenantid )
      const data = {
        user_id: this.uid,
        tenant_id: this.$route.params.id,
        module_id: moduleid,
        role: role,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user-tenant-module/update`,
        data: data,
      };

      this.$http(options)
        .then((res) => {
            this.getModules();
            this.getMembers(this.customerid);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeModule(moduleid){
        const data = {
        user_id: this.uid,
        tenant_id: this.$route.params.id,
        module_id: moduleid,
        remove_access: 1,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user-tenant-module/update`,
        data: data,
      };

      this.$http(options)
        .then((res) => {
            this.getModules();
            this.getMembers(this.customerid);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getModules() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user-tenant-module?user_id=${this.uid}&tenant_id=${this.$route.params.id}`,
      };

      this.$http(options)
        .then((res) => {
          this.module_details = res.data.data;
          this.getModuleList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    enableModule(moduleid,modstatus){
      const data = {
        status: modstatus == true ? 1 : 0,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/companies/${this.$route.params.id}/modules/${moduleid}`,
        data: data,
      };

      this.$http(options)
        .then((res) => {
            this.getModules();
            this.getMembers(this.customerid);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editmodules() {
      const data = {
        modules: this.selectedModules,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/companies/${this.$route.params.id}/modules`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          this.getMembers();
          this.$refs["modal-editmodule"].hide();
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openModuleSelect() {
      this.$refs["modal-editmodule"].show();
      this.getAllModules();
    },
    getAllModules() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/modules`,
      };
      this.$http(options)
        .then((res) => {
          let new_options = [];
          this.modules_list = res.data.data.data;
          this.modules_list.map((item) => {
            const a = {
              text: item.name,
              value: item._id,
            };
            new_options.push(a);
            this.new_option_module = new_options;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteCustomerUser() {
      const data = {
        user_id: this.customeruser,
        tenant_id: this.tenantid,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user/remove-user`,
      };
      this.$http(options)
        .then((res) => {
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            // this.addusertotenant = false;
            this.$refs["modal-danger"].hide();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeUser(userid) {
      this.customeruser = userid;
      this.$refs["modal-danger"].show();
    },
    assignConsultant() {
      const data = {
        user_id: this.selectedconsultant,
        tenant_id: this.tenantid,
        add_to_tenant_db: "1",
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user/add-vm-user`,
      };
      this.$http(options)
        .then((res) => {
          this.getMembers(this.customerid);
          this.$refs["modal-adduser"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.addusertotenant = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openConsultantModal() {
      this.getConsultants();
      this.$refs["modal-adduser"].show();
    },
    getConsultants() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/get?user_type=GRC_user&dont_paginate=1`,
      };
      this.$http(options)
        .then((res) => {
          this.assignusers = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectExistingUser(user) {
      this.selectexist = false;
      // alert(JSON.stringify(user));
      this.existuser = user._id;
    },
    AddExistUserTenant() {
      if (this.emaillist[0]) {
        const data = {
          user_id: this.emaillist[0]._id,
          tenant_id: this.tenantid,
          add_to_tenant_db: "1",
        };
        const options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_SSO_API_URL + `/user/add-existing`,
        };
        this.$http(options)
          .then((res) => {
            this.getMembers(this.customerid);
            if (res.data.status == "error") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            } else {
              this.addusertotenant = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }

            console.log(res.data);
          })
          .catch((err) => {
            this.handleError(err);
          });
      } else {
        console.log("in else");
      }
    },
    getemail() {
      if (this.emailid != "") {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_SSO_API_URL +
            `/user/get?email=${this.emailid}&dont_paginate=1&user_type=Customer`,
        };
        this.$http(options)
          .then((res) => {
            this.emaillist = res.data.data;
            if (res.data.data.length) {
              this.fname = res.data.data[0].firstname;
              this.lname = res.data.data[0].lastname;
              this.phonenumber = res.data.data[0].mobile;
            } else {
              this.fname = "";
              this.lname = "";
              this.phonenumber = "";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    openaddUsertoTenant() {
      this.addusertotenant = true;
    },
    validationFormsub() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.AddUsertoTenant();
        }
      });
    },
    AddUsertoTenant() {
      const data = {
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        tenant_id: this.tenantid,
        user_type: "Customer",
        add_to_tenant_db: "1",
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user/add`,
      };
      this.$http(options)
        .then((res) => {
          this.fname = "";
          this.lname = "";
          this.emailid = "";
          this.phonenumber = "";
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.addusertotenant = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
    editcompanyname(event) {
      const data = {
        company: event.target.value,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/companies/${this.$route.params.id}`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    focusProjectTitleEdit() {
      this.$refs.cname.focus();
    },
    assignuser() {
      const data = {
        tenant_id: this.tenantid,
        user_id: this.selecteduser,
        roles: this.roles,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/customers/${this.tenantid}/assign-vm-user`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-adduser"].hide();
          this.selecteduser = "";
          this.roles = "";
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    assignUser(id) {
      //   this.viewDetailsCompany(id);
      this.$refs["modal-adduser"].show();
    },
    openremoveuser(uid) {
      const data = {
        tenant_id: this.tenantid,
        user_id: uid,
        // roles: this.roles,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/customers/${this.tenantid}/remove_vm_user`,
      };
      this.$http(options)
        .then((res) => {
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.AddUser();
        }
      });
    },
    getMembers(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/companies/${this.$route.params.id}`,
      };
      this.$http(options)
        .then((res) => {
          //   this.companyusers = res.data;
          this.companydetail = res.data.data;

          this.tenantid = res.data.data.tenant_uid;
          // this.getCompanyMembers(this.currentPage);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanyMembers(pageno) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/customers/${this.tenantid}`,
        params: { page: pageno, limit: this.perPage },
      };
      this.$http(options)
        .then((res) => {
          this.companyusers = res.data.data.data.data;
          this.currentPage = res.data.data.data.current_page;
          this.lastPage = res.data.data.data.last_page;
          this.perPage = res.data.data.data.per_page;
          //   this.tenantid = res.data.data.company.tenant_uid
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openAddUser() {
      this.add_user = true;
    },
    closeAdduser() {
      this.addusertotenant = false;
    },
    AddUser() {
      const data = {
        company: this.companyname,
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        should_invite: this.should_invite,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_CISOGRC_API_URL + `/customer/sign-up`,
      };
      this.$http(options)
        .then((res) => {
          this.closeAdduser();
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    invite(uid) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/user/reinvite?user_id=${uid}`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/user/search`,
      };
      this.$http(options)
        .then((res) => {
          this.assignusers = res.data.data;
          for (let i in this.assignusers) {
            let user = {
              title: value,
            };
            this.optionuser.push(user);
          }

          console.log(res.data);
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
  },
};
</script>
<style>
th {
  background: #472183 !important;
  color: #fff;
}
.edit-company {
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 500;
}
.btn-icon {
  cursor: pointer;
}
.delete-color {
  color: #ea5455 !important;
  cursor: pointer;
}
</style>
